import React from 'react'
import { createModalManager, ConfirmModal, ConfirmModalProps, ModalStateType } from '@libs/components'
import MaterialDownloadModal, { MaterialDownloadModalProps } from 'modules/material/MaterialDownloadModal'
import CopyMaterialModal, { CopyMaterialModalProps } from 'modules/material/CopyMaterialModal'
import SaveLinkMaterialModal, { SaveLinkMaterialModalProps } from 'modules/material/SaveLinkMaterialModal'
import EditUserModal, { EditUserModalProps } from 'modules/users/EditUserModal'
import InviteUserModal, { InviteUserModalProps } from 'modules/users/InviteUserModal'
import SaveDocumentMaterialModal, { SaveDocumentMaterialModalProps } from 'modules/material/SaveDocumentMaterialModal'
import CopyMaterialByCaseLocationModal, {
	CopyMaterialByCaseLocationModalProps,
} from 'modules/material/CopyMaterialByCaseLocationModal'
import TaskTextInputModal, { TaskTextInputModalProps } from 'modules/tasks/TaskTextInputModal'
import TaskCoptywriteTextModal, { TaskCopywriteTextModalProps } from 'modules/tasks/TaskCopywriteTextModal'
import TaskSpeakInputModal, { TaskSpeakInputModalProps } from 'modules/tasks/TaskSpeakInputModal'
import TaskBasicModal, { TaskBasicModalProps } from 'modules/tasks/TaskBasicModal'
import TaskIntroOutroModal, { TaskIntroOutroModalProps } from 'modules/tasks/TaskIntroOutroModal'
import TaskVirtualStagingModal, { TaskVirtualStagingModalProps } from 'modules/tasks/TaskVirtualStagingModal'
import TaskRetouchingModal, { TaskRetouchingModalProps } from 'modules/tasks/TaskRetouchingModal'
import TaskVideoInformationModal, { TaskVideoInformationModalProps } from 'modules/tasks/TaskVideoInformationModal'
import CaseTextEditModal, { CaseTextEditModalProps } from 'modules/cases/CaseTextEditModal'
import MaterialCarouselModal, { MaterialCarouselModalProps } from 'modules/material/MaterialCarouselModal'
import ConfirmNewOrderModal from 'modules/orders/ConfirmNewOrderModal'
import ConfirmLeaveNewOrderModal from 'modules/orders/ConfirmLeaveNewOrderModal'
import TaskUploaderModal, { TaskUploaderModalProps } from 'modules/tasks/TaskUploaderModal'
import TaskArialModal, { TaskArialModalProps } from 'modules/tasks/TaskArialModal'
import TaskPersonInfoModal, { TaskPersonInfoModalProps } from 'modules/tasks/TasksPersonInfoModal'
import TaskBookPhotographerModal, { TaskBookPhotographerModalProps } from 'modules/tasks/TaskBookPhotographerModal'
import TaskBeforeAfterModal, { TaskBeforeAfterModalProps } from 'modules/tasks/TaskBeforeAfterModal'
import SupportArticleModal, { SupportArticleModalProps } from 'modules/support/SupportArticleModal'
import CaseAddressEditModal, { CaseAddressEditModalProps } from 'modules/cases/CaseAddressEditModal'
import NewsArticleModal, { NewsArticleModalProps } from 'modules/news/NewsArticleModal'
import CompletedTaskModal, { CompletedTaskModalProps } from 'modules/tasks/CompletedTaskModal'
import CaseAddExtraOrderLineModal, { CaseAddExtraOrderLineModalProps } from 'modules/cases/CaseAddExtraOrderLineModal'
import NewTasksModal, { NewTasksModalProps } from 'modules/tasks/NewTasksModal'
import TaskSocialMediaModal, { TaskSocialMediaModalProps } from 'modules/tasks/TaskSocialMediaModal'
import TaskSocialMediaSlideshowModal, {
	TaskSocialMediaSlideshowModalProps,
} from 'modules/tasks/TaskSocialMediaSlideshowModal'
import AddressModal, { AddressModalProps } from 'modules/common/AddressModal'
import OrderAlreadyOpenedModal, { OrderAlreadyOpenedModalProps } from 'modules/orders/OrderAlreadyOpenedModal'
import MaterialBatchRemovalModal, { MaterialBatchRemovalModalProps } from 'modules/material/MaterialBatchRemovalModal'
import TaskImageSelectorModal, { TaskImageSelectorModalProps } from 'modules/tasks/TaskImageSelectorModal'
import CollageModal, { CollageModalProps } from 'modules/material/CollageModal'

type ModalMapType =
	| ModalStateType<'tasks.uploader', TaskUploaderModalProps>
	| ModalStateType<'order.new.confirm', Record<string, never>>
	| ModalStateType<'order.new.leave.confirm', Record<string, never>>
	| ModalStateType<'order.new.already.open', OrderAlreadyOpenedModalProps>
	| ModalStateType<'case.text.edit', CaseTextEditModalProps>
	| ModalStateType<'case.add.extra.order.line', CaseAddExtraOrderLineModalProps>
	| ModalStateType<'case.address.edit', CaseAddressEditModalProps>
	| ModalStateType<
			| 'material.archive'
			| 'material.link.remove'
			| 'material.document.remove'
			| 'users.invite.remove'
			| 'order.new.copyright.alert'
			| 'material.recreate'
			| 'users.revoke.access'
			| 'tasks.book.confirmed'
			| 'order.new.successful',
			Pick<ConfirmModalProps, 'onConfirm' | 'onCancel'>
	  >
	| ModalStateType<'material.download', MaterialDownloadModalProps>
	| ModalStateType<'material.copy', CopyMaterialModalProps>
	| ModalStateType<'material.link.save', SaveLinkMaterialModalProps>
	| ModalStateType<'material.batch.remove', MaterialBatchRemovalModalProps>
	| ModalStateType<'material.collage', CollageModalProps>
	| ModalStateType<'users.edit', EditUserModalProps>
	| ModalStateType<'users.invite', InviteUserModalProps>
	| ModalStateType<'material.document.save', SaveDocumentMaterialModalProps>
	| ModalStateType<'material.location.copy', CopyMaterialByCaseLocationModalProps>
	| ModalStateType<'tasks.text.input', TaskTextInputModalProps>
	| ModalStateType<'tasks.copywrite.text', TaskCopywriteTextModalProps>
	| ModalStateType<'tasks.basic', TaskBasicModalProps>
	| ModalStateType<'tasks.basic.opt', TaskBasicModalProps>
	| ModalStateType<'tasks.book.photographer', TaskBookPhotographerModalProps>
	| ModalStateType<'tasks.intro.outro', TaskIntroOutroModalProps>
	| ModalStateType<'tasks.speak.input', TaskSpeakInputModalProps>
	| ModalStateType<'tasks.virtual.staging', TaskVirtualStagingModalProps>
	| ModalStateType<'tasks.retouching', TaskRetouchingModalProps>
	| ModalStateType<'tasks.vidibili.comment', TaskVideoInformationModalProps>
	| ModalStateType<'material.carousel', MaterialCarouselModalProps>
	| ModalStateType<'tasks.arial', TaskArialModalProps>
	| ModalStateType<'tasks.person.info', TaskPersonInfoModalProps>
	| ModalStateType<'tasks.beforeAfter', TaskBeforeAfterModalProps>
	| ModalStateType<'support.article', SupportArticleModalProps>
	| ModalStateType<'new.tasks', NewTasksModalProps>
	| ModalStateType<'news', NewsArticleModalProps>
	| ModalStateType<'tasks.completed', CompletedTaskModalProps>
	| ModalStateType<'tasks.social.media', TaskSocialMediaModalProps>
	| ModalStateType<'tasks.social.media.slideshow', TaskSocialMediaSlideshowModalProps>
	| ModalStateType<'address.edit', AddressModalProps>
	| ModalStateType<'tasks.social.media.gif', TaskBeforeAfterModalProps>
	| ModalStateType<'tasks.image.selector', TaskImageSelectorModalProps>

const { Provider, useShowModal } = createModalManager(({ id, params }: ModalMapType) => {
	switch (id) {
		case 'order.new.confirm':
			return <ConfirmNewOrderModal />

		case 'order.new.leave.confirm':
			return <ConfirmLeaveNewOrderModal />

		case 'case.text.edit':
			return <CaseTextEditModal {...(params as CaseTextEditModalProps)} />

		case 'case.add.extra.order.line':
			return <CaseAddExtraOrderLineModal {...(params as CaseAddExtraOrderLineModalProps)} />

		case 'case.address.edit':
			return <CaseAddressEditModal {...(params as CaseAddressEditModalProps)} />

		case 'material.archive':
		case 'material.document.remove':
		case 'material.link.remove':
		case 'material.recreate':
		case 'order.new.copyright.alert':
		case 'users.invite.remove':
		case 'users.revoke.access':
		case 'tasks.book.confirmed':
		case 'order.new.successful':
			return <ConfirmModal {...(params as ConfirmModalProps)} />

		case 'material.download':
			return <MaterialDownloadModal {...(params as MaterialDownloadModalProps)} />

		case 'material.copy':
			return <CopyMaterialModal {...(params as CopyMaterialModalProps)} />

		case 'material.link.save':
			return <SaveLinkMaterialModal {...(params as SaveLinkMaterialModalProps)} />

		case 'material.document.save':
			return <SaveDocumentMaterialModal {...(params as SaveDocumentMaterialModalProps)} />

		case 'material.location.copy':
			return <CopyMaterialByCaseLocationModal {...(params as CopyMaterialByCaseLocationModalProps)} />

		case 'new.tasks':
			return <NewTasksModal {...(params as NewTasksModalProps)} />

		case 'users.edit':
			return <EditUserModal {...(params as EditUserModalProps)} />

		case 'tasks.book.photographer':
			return <TaskBookPhotographerModal {...(params as TaskBookPhotographerModalProps)} />

		case 'users.invite':
			return <InviteUserModal {...(params as InviteUserModalProps)} />

		case 'tasks.copywrite.text':
			return <TaskCoptywriteTextModal {...(params as TaskCopywriteTextModalProps)} />

		case 'tasks.text.input':
			return <TaskTextInputModal {...(params as TaskTextInputModalProps)} />

		case 'tasks.basic':
			return <TaskBasicModal {...(params as TaskBasicModalProps)} />

		case 'tasks.basic.opt':
			return <TaskBasicModal optional {...(params as TaskBasicModalProps)} />

		case 'tasks.virtual.staging':
			return <TaskVirtualStagingModal {...(params as TaskVirtualStagingModalProps)} />

		case 'tasks.uploader':
			return <TaskUploaderModal {...(params as TaskUploaderModalProps)} />

		case 'tasks.intro.outro':
			return <TaskIntroOutroModal {...(params as TaskIntroOutroModalProps)} />

		case 'tasks.retouching':
			return <TaskRetouchingModal {...(params as TaskRetouchingModalProps)} />

		case 'tasks.speak.input':
			return <TaskSpeakInputModal {...(params as TaskSpeakInputModalProps)} />

		case 'tasks.vidibili.comment':
			return <TaskVideoInformationModal {...(params as TaskVideoInformationModalProps)} />

		case 'material.carousel':
			return <MaterialCarouselModal {...(params as MaterialCarouselModalProps)} />

		case 'tasks.person.info':
			return <TaskPersonInfoModal {...(params as TaskPersonInfoModalProps)} />

		case 'tasks.arial':
			return <TaskArialModal {...(params as TaskArialModalProps)} />

		case 'tasks.beforeAfter':
		case 'tasks.social.media.gif':
			return <TaskBeforeAfterModal {...(params as TaskBeforeAfterModalProps)} />

		case 'support.article':
			return <SupportArticleModal {...(params as SupportArticleModalProps)} />

		case 'news':
			return <NewsArticleModal {...(params as NewsArticleModalProps)} />

		case 'tasks.completed':
			return <CompletedTaskModal {...(params as CompletedTaskModalProps)} />

		case 'tasks.social.media':
			return <TaskSocialMediaModal {...(params as TaskSocialMediaModalProps)} />

		case 'tasks.social.media.slideshow':
			return <TaskSocialMediaSlideshowModal {...(params as TaskSocialMediaSlideshowModalProps)} />

		case 'tasks.image.selector':
			return <TaskImageSelectorModal {...(params as TaskImageSelectorModalProps)} />

		case 'address.edit':
			return <AddressModal {...(params as AddressModalProps)} />

		case 'order.new.already.open':
			return <OrderAlreadyOpenedModal {...(params as OrderAlreadyOpenedModalProps)} />

		case 'material.batch.remove':
			return <MaterialBatchRemovalModal {...(params as MaterialBatchRemovalModalProps)} />

		case 'material.collage':
			return <CollageModal {...(params as CollageModalProps)} />

		default:
			return null
	}
})

export { useShowModal }

export default { Provider }
