import React, { FC, useEffect } from 'react'
import Head from 'next/head'
import { useLocalization } from '@libs/localization'
import { Layout, Typography } from 'antd'
import { DocumentUnknown32 } from '@carbon/icons-react'
import Link from 'next/link'
import Styled from './Styled'
import { useWhitelabelSettings } from 'modules/branches/hooks'
import { AuthApi, IDatahubCase, IUser } from '@libs/api'
import * as Sentry from '@sentry/nextjs'

export interface ErrorPageProps {
	standalonePage?: boolean
	contentChild?: JSX.Element
	title?: string
	logoLink?: string
	doesCurrentUserHaveAccess?: boolean
	user?: IUser
	foundCase?: IDatahubCase
	source?: 'new-order-for-case'
}

const ErrorPage: FC<ErrorPageProps> = ({
	standalonePage = false,
	contentChild,
	title,
	logoLink,
	doesCurrentUserHaveAccess,
	user,
	foundCase,
	source,
}) => {
	const { t } = useLocalization()
	const { data: whitelabel } = useWhitelabelSettings(AuthApi.isAuthenticated())

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureEvent({
				message: `404 page displayed${source ? ` for ${source}` : ''}`,
				extra: {
					url: window.location.href,
					doesCurrentUserHaveAccess,
					user: user ? JSON.stringify(user) : undefined,
					foundCase: foundCase ? JSON.stringify(foundCase) : undefined,
				},
			})
		}
	}, [])

	const Logo = (
		<Link href={logoLink || '/'} passHref>
			<a>
				<Styled.Logo src={whitelabel?.logoUrl || '/logo.png'} />
			</a>
		</Link>
	)

	const defaultBodyContent = (
		<Styled.Container
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			standalonePage={standalonePage}
		>
			<Typography>404: {t(`common.messages.resourceNotFound`)}</Typography>
			<DocumentUnknown32 />
		</Styled.Container>
	)

	return (
		<>
			<Head>
				<title>{title || `404: ${t('common.messages.resourceNotFound')}`}</title>
			</Head>
			<Layout>
				<Layout.Content>
					{standalonePage && Logo}
					{contentChild || defaultBodyContent}
				</Layout.Content>
			</Layout>
		</>
	)
}

export default ErrorPage
